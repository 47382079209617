













import { Component, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import PageHeader from '@/admin/components/PageHeader.vue'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/form/form-base'
import User from '@/shared/modules/user/user.model'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import { AuthActions } from '@/shared/store/auth/auth.actions'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import ISnackBar from '@/shared/interfaces/helpers/snack-bar.interface'
import UserCourseStatistic from "@/shared/components/UserCourseStatistic.vue";
import RoleCan from "@/shared/components/RoleCan.vue";
import { RoleLevel } from '../modules/role/role.model'

@Component({
  components: { Form, PageHeader, UserCourseStatistic, RoleCan },
  methods: { __ }
})
export default class Profile extends Vue {
  RoleLevel = RoleLevel

  profileForm: FormBase = new FormBase()
    .setModel(User)
    .setEntry(this.$store.getters[AuthGetters.getUser])
    .setEndpoint('update-profile')
    .setFiles(true)
    .setFields([
      new Field()
        .setType(FieldTypes.file)
        .setKey('avatar')
        .setTitle(__('views.profile.sections.main-info.form.avatar'))
        .setMeta({ type: 'image', accept: 'image/*' }),
      new Field()
        .setKey('first_name')
        .setEntryKey('firstName')
        .setTitle(__('views.profile.sections.main-info.form.first_name'))
        .setSize(FieldSizes.half),
      new Field()
        .setEntryKey('lastName')
        .setKey('last_name')
        .setTitle(__('views.profile.sections.main-info.form.last_name'))
        .setSize(FieldSizes.half),
      new Field()
        .setType(FieldTypes.email)
        .setKey('email')
        .setTitle(__('views.profile.sections.main-info.form.email'))
        .setDisabled(true),
      new Field()
        .setKey('phone')
        .setTitle(__('views.profile.sections.main-info.form.phone'))
        .setSize(FieldSizes.half),
      new Field()
        .setType(FieldTypes.datePicker)
        .setEntryKey('birthDate')
        .setKey('birth_date')
        .setTitle(__('views.profile.sections.main-info.form.birth_date'))
        .setSize(FieldSizes.half),
      new Field()
        .setKey('address')
        .setTitle(__('views.profile.sections.main-info.form.address')),
    ])
    .setOnSuccess(this.profileFormOnSuccess)

  passwordForm: FormBase = new FormBase()
    .setEndpoint('update-profile-password')
    .setFields([
      new Field()
        .setType(FieldTypes.password)
        .setKey('old_password')
        .setTitle(__('views.profile.sections.password-change.form.old-password')),
      new Field()
        .setType(FieldTypes.password)
        .setKey('password')
        .setTitle(__('views.profile.sections.password-change.form.password')),
      new Field()
        .setType(FieldTypes.password)
        .setKey('password_confirmation')
        .setTitle(__('views.profile.sections.password-change.form.password-confirmation')),
    ])
    .setSubmit({
      text: __('views.profile.sections.password-change.form.submit'),
    })
    .setOnSuccess(this.updateProfilePasswordOnSuccess)

  private profileFormOnSuccess(): void {
    this.$store.dispatch(AuthActions.me)
    this.$store.dispatch(GlobalActions.showSnackBar, {
      type: SnackBarTypes.success,
      message: __('views.profile.sections.main-info.form.on-success')
    } as ISnackBar)
  }

  private updateProfilePasswordOnSuccess(): void {
    const passwordForm: Form | any = this.$refs.passwordForm
    passwordForm.reset()
    this.$store.dispatch(GlobalActions.showSnackBar, {
      type: SnackBarTypes.success,
      message: __('views.profile.sections.password-change.form.on-success'),
    })
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }
}
